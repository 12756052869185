import React from "react";
import Xarrow from "react-xarrows";
import { useRef } from "react";
import { useState, useEffect } from "react";
import "./appoinment.css";
import constant from "../js/constant";
import { ApiGetMethod, ApiPostMethod } from "../js/helper";
import Swal from "sweetalert2";
import demoimage from "../../image/demo-car.png";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Appoinment = () => {
  const Swal = require("sweetalert2");
  let navigate = useNavigate();
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);
  const [isActive, setIsActive] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleOnClick = (index) => {
    const newIsActive = Array(6).fill(false);
    newIsActive[index] = true;
    setIsActive(newIsActive);
  };
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const daysStartingFromTomorrow = daysOfWeek
    .slice(new Date().getDay() + 1)
    .concat(daysOfWeek.slice(0, new Date().getDay() + 1));

  const [selectedDays, setSelectedDays] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [showSlots, setShowSlots] = useState(false);
  const [slotTime, setSlotTime] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState([]);
  const formData = new FormData();

  const handleCheckboxChange = (e) => {
    const day = e.target.value;
    if (e.target.checked) {
      setSelectedDays([selectedDays, day]);
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    }
  };

  const handleSlots = (e) => {
    var cusid_ele = document.getElementsByClassName("datepckerapp");
    for (var i = 0; i < cusid_ele.length; ++i) {
      var item = cusid_ele[i];
      item.setAttribute("class", "datepckerapp col-3 m-1 ");
    }
    const dateSlot = new Date(e.target.value);
    const dateString = dateSlot.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [day, month, year] = dateString.split("/");
    const formattedDate = `${year}-${month}-${day}`;
    setAppointmentDate(formattedDate);
    var parentele = e.target.parentElement.parentElement;
    parentele.setAttribute("class", "datepckerapp col-4 m-1 activeDate");
    if (e.target.checked) {
      formData.append("date", formattedDate);
      let data = formData;
      let url = `${constant.baseurl}getSlots`;
      ApiPostMethod(url, data).then((response) => {
        setSlotTime(response.slots);
        setShowSlots(true);
        let numbers = Object.values(response.slots);
        var x = 0;

        for (var i = 0; i < numbers.length; i++) {
          x = document.getElementById("s" + i);
          x.checked = false;
          numbers[i]
            ? x.removeAttribute("disabled")
            : x.setAttribute("disabled", "disabled");
        }
      });
    } else {
      setShowSlots(false);
    }
  };

  const values = [
    "09:00 AM - 11:00 AM",
    "11:00 AM - 13:00 PM",
    "13:00 PM - 15:00 PM",
    "15:00 PM - 17:00 PM",
    "17:00 PM - 19:00 PM",
  ];
  const [selectedSlotValue, setSelectedSlotValue] = useState();
  const handleSlotValue = (index) => {
    const slotValue = values[index];
    setSelectedSlotValue(slotValue);
  };
  useEffect(() => {
  }, [selectedSlotValue]);

  const radioButtons = document.getElementsByName("time-slot");
  radioButtons.forEach((radioButton) => {
    const parentDiv = radioButton.parentElement;
    if (radioButton.checked) {
      parentDiv.classList.add("selectedSlot");
    } else {
      parentDiv.classList.remove("selectedSlot");
    }
  });

  const location = useLocation();
  const query_Params = new URLSearchParams(location.search);
  const carPost = query_Params.get("carPostId");

  const [userID, setUserID] = useState();
  const [userCity, setUserCity] = useState();
  const [userPlotNo, setUserPlotNo] = useState();
  const [userStreet, setUserStreet] = useState();
  const [userArea, setUserArea] = useState();
  const [userPinCode, setUserPinCode] = useState();
  const [carPostId, setCarPostId] = useState(carPost || "");
  const [carPostCity, setCarPostCity] = useState();
  const [carPostFuelType, setCarPostFuelType] = useState();
  const [carPostImage, setCarPostImage] = useState([]);
  const [carPostDepricationPrice, setCarPostDepricationPrice] = useState([]);
  const [carPostDetails, setCarPostDetails] = useState([]);
  const [carPostBrandName, setCarPostBrandName] = useState([]);
  const [carDataEdit, setCarDataEdit] = useState({});

  useEffect(() => {
    const userDetails = localStorage.hasOwnProperty("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    const PostID = localStorage.hasOwnProperty("deprication")
      ? JSON.parse(localStorage.getItem("deprication"))
      : "";
    setCarDataEdit(PostID.PostDetails);
    setCarPostId(carPost || PostID.PostDetails._id);
    setCarPostCity(PostID.PostDetails.city);
    setCarPostFuelType(PostID.CarDataDetail[0].fuelType);
    setCarPostImage(PostID.PostDetails);
    setCarPostDepricationPrice(PostID.depriciation);
    setUserID(userDetails._id);
    setUserCity(window.city);
    setCarPostDetails(PostID.PostDetails);
    setCarPostBrandName(PostID.carBrand);
  }, []);

  const handleChange = (event) => {
    setUserPlotNo(event.target.value);
  };
  const handleChangestreet = (event) => {
    setUserStreet(event.target.value);
  };
  const handleChangearea = (event) => {
    setUserArea(event.target.value);
  };
  const handleChangepincode = (event) => {
    setUserPinCode(event.target.value);
  };
  const handleBookAppointment = () => {
    // Check if all fields are filled
    // if (
    //   !userPlotNo ||
    //   !userStreet ||
    //   !userArea ||
    //   !userPinCode ||
    //   !appointmentDate ||
    //   !selectedSlotValue
    // ) {
    //   alert("Please fill in all fields");
    //   return;
    // }

    // // Check if pincode has exactly 6 digits
    // if (userPinCode.length !== 6) {
    //   alert("Pincode should have exactly 6 digits");
    //   return;
    // }

    if (
      !userPlotNo ||
      !userPlotNo.trim() ||
      !userStreet ||
      !userStreet.trim() ||
      !userArea ||
      !userArea.trim() ||
      !userPinCode ||
      !userPinCode.trim() ||
      !appointmentDate ||
      !selectedSlotValue
    ) {
      // alert("Please fill in all fields");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill in all fields!",
      });
      return;
    }

    // Check if pincode has exactly 6 digits
    if (userPinCode.trim().length !== 6) {
      // alert("Pincode should have exactly 6 digits");
      Swal.fire({
        icon: "info",
        title: "Info",
        text: "Pincode should have exactly 6 digits!",
      });
      return;
    }
    formData.append("carPostId", carPostId);
    formData.append("userPlotNo", userPlotNo);
    formData.append("userArea", userArea);
    formData.append("userPin", userPinCode);
    formData.append("userStreet", userStreet);
    formData.append("date", appointmentDate);
    formData.append("slot", selectedSlotValue);
    formData.append("userId", userID);
    formData.append("userCity", userCity);
    let data = formData;
    let url = `${constant.baseurl}bookAppointment`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (response.error == false) {
            navigate("/UserProfilePage?activeTabKey=carPost");
          } else {
            alert(result);
          }
        });
        localStorage.setItem("appointment", JSON.stringify(response));
      } else {
        Swal.fire({
          title: "Error!",
          text: response.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  };
  const postSuccess = () => {
    Swal.fire({
      title: "Success",
      text: "Admin will approve your request soon.",
      icon: "success",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result) {
        navigate("/index");
      } else {
        navigate("/Appoinment");
      }
    });
  };

  return (
    <>
      <section className="appoinment top_class" id="appionment-section">
        <div className="container-fluid">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="col-8 appoint_wrap_top">
              <div className="col-12 d-flex justify-content-between appoint_wrapper">
                <div
                  className="col-6 car_details"
                  style={{ width: carPostCity == "pune" ? "49%" : "55%" }}
                >
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <a
                      href={"/carpost?carPostId=" + carDataEdit._id}
                      className="edit-detailsbtn"
                    >
                      Edit Car Details
                    </a>
                  </div>
                  <div className="col-12">
                    <div className="car-header">
                      <h5>Check Car Condition</h5>
                    </div>
                    <div className="col-12">
                      <ul className="condition-list col-12 d-flex">
                        <li
                          className={
                            isActive[0]
                              ? "condition-item col-3 active-dot"
                              : "condition-item col-3"
                          }
                          onClick={() => handleOnClick(0)}
                        >
                          <h6 className="m-0">Fair</h6>
                        </li>
                        <li
                          className={
                            isActive[1]
                              ? "condition-item col-3 active-dot"
                              : "condition-item col-3"
                          }
                          onClick={() => handleOnClick(1)}
                        >
                          <h6 className="m-0">Good</h6>
                        </li>
                        <li
                          className={
                            isActive[2]
                              ? "condition-item col-3 active-dot"
                              : "condition-item col-3"
                          }
                          onClick={() => handleOnClick(2)}
                        >
                          <h6 className="m-0">Very Good</h6>
                        </li>
                        <li
                          className={
                            isActive[3]
                              ? "condition-item col-3 active-dot"
                              : "condition-item col-3"
                          }
                          onClick={() => handleOnClick(3)}
                        >
                          <h6 className="m-0">Excellent</h6>
                        </li>
                      </ul>
                      <div className="col-12 condition-bar d-flex">
                        <div
                          className={
                            isActive[0]
                              ? "col-3 condition-info active-dot"
                              : "col-3 condition-info"
                          }
                          onClick={() => handleOnClick(0)}
                        >
                          <div
                            className="condition-dot dot-1"
                            ref={box1Ref}
                          ></div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                ₹ {carPostDepricationPrice.lowerDeltaExShowroom}
                              </Tooltip>
                            }
                            show={isActive[0]}
                          >
                            <div className="condition-dot dot-1"></div>
                          </OverlayTrigger>
                        </div>
                        <div
                          className={
                            isActive[1]
                              ? "col-3 condition-info active-dot"
                              : "col-3 condition-info"
                          }
                          onClick={() => handleOnClick(1)}
                        >
                          <div
                            className="condition-dot dot-2"
                            ref={box2Ref}
                            id="dot_1"
                          ></div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                ₹ {carPostDepricationPrice.goodDeltaExShowroom}
                              </Tooltip>
                            }
                            show={isActive[1]}
                          >
                            <div className="condition-dot dot-2"></div>
                          </OverlayTrigger>
                        </div>
                        <div
                          className={
                            isActive[2]
                              ? "col-3 condition-info active-dot"
                              : "col-3 condition-info"
                          }
                          onClick={() => handleOnClick(2)}
                        >
                          <div
                            className="condition-dot dot-3"
                            ref={box3Ref}
                            id="dot_2"
                          ></div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                ₹{" "}
                                {
                                  carPostDepricationPrice.veryGoodDeltaExShowroom
                                }
                              </Tooltip>
                            }
                            show={isActive[2]}
                          >
                            <div className="condition-dot dot-3"></div>
                          </OverlayTrigger>
                        </div>
                        <div
                          className={
                            isActive[3]
                              ? "col-3 condition-info active-dot"
                              : "col-3 condition-info"
                          }
                          onClick={() => handleOnClick(3)}
                        >
                          <div className="condition-dot dot-4" id="dot_3"></div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                ₹{" "}
                                {carPostDepricationPrice.HigherDeltaExShowroom}
                              </Tooltip>
                            }
                            show={isActive[3]}
                          >
                            <div className="condition-dot dot-4"></div>
                          </OverlayTrigger>
                        </div>
                        <Xarrow
                          start={box1Ref}
                          end="dot_1"
                          path="smooth"
                          curveness={0.2}
                          showHead={false}
                          strokeWidth={1}
                          color="#BCBABA"
                        />
                        <Xarrow
                          start={box2Ref}
                          end="dot_2"
                          path="smooth"
                          curveness={0.2}
                          showHead={false}
                          strokeWidth={1}
                          color="#BCBABA"
                        />
                        <Xarrow
                          start={box3Ref}
                          end="dot_3"
                          path="smooth"
                          curveness={0.2}
                          showHead={false}
                          strokeWidth={1}
                          color="#BCBABA"
                        />
                      </div>
                      <div className="col-12 d-flex justify-content-center">
                        <div className="col-10">
                          <h6 className="m-0">
                            {" "}
                            ₹{" "}
                            <span>
                              {carPostDepricationPrice.lowerDeltaExShowroom}
                            </span>{" "}
                            - ₹{" "}
                            <span>
                              {carPostDepricationPrice.HigherDeltaExShowroom}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    carPostCity == "pune"
                      ? "col-6 car_details car_det11"
                      : "col-6 car_details d-none"
                  }
                >
                  <div className="col-12">
                    <div className="car-header">
                      <h5>Schedule a free doorstep inspection</h5>
                    </div>
                    <div className="col-12">
                      <form action="">
                        <div className="col-12 d-flex flex-wrap justify-content-between">
                          <div className="form-group col-4">
                            <label htmlFor="">Flat / Plot No.</label>
                            <input
                              type="text"
                              className="form-control"
                              name="PlotNo"
                              id="appointmentPlotNo"
                              placeholder="Enter no."
                              value={userPlotNo}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-8 ps-2">
                            <label htmlFor="">Street.</label>
                            <input
                              type="text"
                              className="form-control"
                              name="PlotNo"
                              id="appointmentPlotNo"
                              placeholder="Street."
                              value={userStreet}
                              onChange={handleChangestreet}
                            />
                          </div>
                          <div className="form-group col-8 pe-2">
                            <label htmlFor="">Area.</label>
                            <input
                              type="text"
                              className="form-control"
                              name="PlotNo"
                              id="appointmentPlotNo"
                              placeholder="Area."
                              value={userArea}
                              onChange={handleChangearea}
                            />
                          </div>
                          <div className="form-group col-4">
                            <label htmlFor="">Pincode.</label>
                            <input
                              type="number"
                              className="form-control"
                              name="PlotNo"
                              id="appointmentPlotNo"
                              placeholder="Enter code."
                              value={userPinCode}
                              onChange={handleChangepincode}
                            />
                          </div>
                        </div>
                        <div
                          className="form-group"
                          style={{ overflow: "auto" }}
                        >
                          <div className="col-12 input-date d-flex">
                            {daysStartingFromTomorrow.map((day, index) => {
                              const date = new Date(
                                currentDate.getFullYear(),
                                currentDate.getMonth(),
                                currentDate.getDate() + index
                              );
                              const formattedDate = date
                                .toLocaleDateString("en-US", {
                                  day: "numeric",
                                  month: "short",
                                })
                                .split(" ")
                                .reverse()
                                .join(" ");
                              return (
                                <div className="col-3 m-1 datepckerapp">
                                  <label key={day} className="date-label">
                                    {index === 0 ? (
                                      <span className="tomoto">Tomorrow</span>
                                    ) : (
                                      <span></span>
                                    )}
                                    <input
                                      type="radio"
                                      value={date}
                                      onChange={handleCheckboxChange}
                                      onClick={handleSlots}
                                      name="date"
                                      id="date_select"
                                      hidden
                                    />

                                    <h6 className="m-0 mt-2">{day}</h6>
                                    <p className="m-0">{formattedDate}</p>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div
                          className={
                            showSlots ? "form-group" : "form-group d-none"
                          }
                        >
                          <div className="slot-header">
                            <h5>Select Time Slot</h5>
                          </div>
                          <div className="col-12 d-flex flex-wrap">
                            <div className="col-4 p-1">
                              <div className="col-12 text-center">
                                <input
                                  type="radio"
                                  id="s0"
                                  name="time-slot"
                                  value="0"
                                  hidden
                                  onChange={() => handleSlotValue(0)}
                                />
                                <label htmlFor="s0" class="font_size">
                                  9:00AM - 11:00AM
                                </label>
                              </div>
                            </div>
                            <div className="col-4 p-1">
                              <div className="col-12 text-center">
                                <input
                                  type="radio"
                                  id="s1"
                                  name="time-slot"
                                  hidden
                                  value="1"
                                  onChange={() => handleSlotValue(1)}
                                />
                                <label htmlFor="s1" class="font_size">
                                  11:00AM - 13:00PM
                                </label>
                              </div>
                            </div>
                            <div className="col-4 p-1">
                              <div className="col-12 text-center">
                                <input
                                  type="radio"
                                  id="s2"
                                  name="time-slot"
                                  hidden
                                  value="2"
                                  onChange={() => handleSlotValue(2)}
                                />
                                <label htmlFor="s2" class="font_size">
                                  13:00PM - 15:00PM
                                </label>
                              </div>
                            </div>
                            <div className="col-4 p-1">
                              <div className="col-12 text-center">
                                <input
                                  type="radio"
                                  id="s3"
                                  name="time-slot"
                                  hidden
                                  value="3"
                                  onChange={() => handleSlotValue(3)}
                                />
                                <label htmlFor="s3" class="font_size">
                                  15:00PM - 17:00PM
                                </label>
                              </div>
                            </div>
                            <div className="col-4 p-1">
                              <div className="col-12 text-center">
                                <input
                                  type="radio"
                                  id="s4"
                                  name="time-slot"
                                  hidden
                                  value="4"
                                  onChange={() => handleSlotValue(4)}
                                />
                                <label htmlFor="s4" class="font_size">
                                  17:00PM - 19:00PM
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-cars2cars"
                            type="button"
                            onClick={handleBookAppointment}
                          >
                            Book Appointment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    carPostCity != "pune"
                      ? "col-5 car-details car_det12"
                      : "col-5 car-details d-none"
                  }
                >
                  <img
                    src={carPostCity != "pune" ? carPostImage.carImages : ""}
                    alt=""
                  />
                  <Col lg="12" className="details">
                    <Col lg="12" className="d-flex justify-content-between">
                      <Col lg="10">
                        <h5 className="mb-1">
                          {carPostBrandName.brandName} {carPostDetails.year}
                        </h5>
                        <h5 className="mb-2">
                          {carPostDetails.model} {carPostDetails.varient} -{" "}
                          <span className="text-uppercase">{carPostDetails.carNumber
                            ? carPostDetails.carNumber
                                .replace(/\s/g, "")
                                .substring(0, 4) + "-XX-XXXX"
                            : ""}
                          </span>
                        </h5>
                      </Col>
                      <Col lg="2">
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30.3919 6.72289C29.6471 5.97769 28.7627 5.38654 27.7893 4.98322C26.8159 4.5799 25.7726 4.37231 24.719 4.37231C23.6654 4.37231 22.6221 4.5799 21.6487 4.98322C20.6753 5.38654 19.7909 5.97769 19.0461 6.72289L17.5003 8.26873L15.9544 6.72289C14.4499 5.21834 12.4093 4.37309 10.2815 4.37309C8.15375 4.37309 6.11314 5.21834 4.60859 6.72289C3.10404 8.22744 2.25879 10.2681 2.25879 12.3958C2.25879 14.5236 3.10404 16.5642 4.60859 18.0687L6.15442 19.6146L17.5003 30.9604L28.8461 19.6146L30.3919 18.0687C31.1371 17.3239 31.7283 16.4395 32.1316 15.4661C32.5349 14.4927 32.7425 13.4494 32.7425 12.3958C32.7425 11.3422 32.5349 10.2989 32.1316 9.3255C31.7283 8.35212 31.1371 7.46775 30.3919 6.72289Z"
                            fill="#FFFEFE"
                            stroke="#D9D9D9"
                            stroke-width="2.04723"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Col>
                    </Col>
                    <Col lg="12">
                      <h6>
                        {(carPostDetails.kms / 1000).toLocaleString("en-US", {
                          maximumFractionDigits: 1,
                        }) + "k"}{" "}
                        km{" "}
                        <span>
                          <svg
                            width="5"
                            height="5"
                            viewBox="0 0 5 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="2.5" cy="2.5" r="2.5" fill="black" />
                          </svg>
                        </span>{" "}
                        <span className="text-capitalize">
                          {carPostFuelType}
                        </span>{" "}
                        <span>
                          <svg
                            width="5"
                            height="5"
                            viewBox="0 0 5 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="2.5" cy="2.5" r="2.5" fill="black" />
                          </svg>{" "}
                        </span>
                        {carPostDetails.transmission}
                      </h6>
                    </Col>
                  </Col>
                  <Col lg="12" className="text-center pt-2 pb-2">
                    <Button
                      size="md"
                      className="btn-postCar"
                      onClick={postSuccess}
                    >
                      Post Car
                    </Button>
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Appoinment;
