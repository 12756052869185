import React, { useState, useRef, useEffect } from "react";
import { ApiGetMethod, ApiPostMethod } from "../../js/helper";
import constant, { colorOptions } from "../../js/constant";
import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
// import constant, { colorOptions } from '../../js/constant';
// import Swal from 'sweetalert2';
import { useLocation, useNavigate } from "react-router-dom";

const MyForm = () => {
  const formData = new FormData();
  let navigate = useNavigate();

  const location = useLocation();
  const query_Params = new URLSearchParams(location.search);
  const editCarPostId = query_Params.get("carPostId");
  const [editCarpost, setEditCarpost] = useState(editCarPostId || "");
  const [editCardata, setEditCardata] = useState();

  const [editCity, setEditCity] = useState();
  const [editBrand, setEditBrand] = useState();
  const [editCnumber, setEditCnumber] = useState();
  const [editYear, setEditYear] = useState();
  const [editModel, setEditModel] = useState();
  const [editVariant, setEditVariant] = useState();
  const [editOwner, setEditOwner] = useState();
  const [editKms, setEditKms] = useState();
  const [editWtsell, setEditWtsell] = useState();
  const [editInsurance, setEditInsurance] = useState();
  const [editRc, setEditRc] = useState();
  const [editTransmission, setEditTransmission] = useState();
  const [editColor, setEditColor] = useState();
  const [editImage, setEditImage] = useState([]);

  useEffect(() => {
    formData.append("carPostId", editCarpost);
    let data = formData;
    let url = `${constant.baseurl}carDataView`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        setEditCardata(response.carPostData);
        setEditCity(response.carPostData.city);
        setEditBrand(response.carPostData.brandId);
        setEditCnumber(response.carPostData.carNumber);
        setEditYear(response.carPostData.year);
        setEditModel(response.carPostData.model);
        setEditVariant(response.carPostData.varient);
        setEditOwner(response.carPostData.owner);
        setEditKms(response.carPostData.kms);
        setEditWtsell(response.carPostData.whenToSell);
        setEditInsurance(response.carPostData.insurence);
        setEditRc(response.carPostData.rc);
        setEditTransmission(response.carPostData.transmission);
        setEditColor(response.carPostData.color);
        setEditImage(response.carPostData.carImages);
      } else {
      }
    });
  }, []);
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImageUrls, setPreviewImageUrls] = useState([]);
  const inputRef = useRef([]);

  // this.urlPromoRef = React.createRef()
  const handleImageSelection = (event) => {
    const selectedFiles = event.target.files;
    let editImage = event.target.files;
    const imageUrls = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];

      const url = URL.createObjectURL(file);
      imageUrls.push(url);
      window.img = selectedFiles[i];
    }

    setSelectedImages(selectedFiles);
    setEditImage(selectedFiles);
    setPreviewImageUrls(imageUrls);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  function handleFocus() {
    setShowDropdown(true);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }

  // function handleOptionClick(value) {
  //   const uppercaseValue = value.toUpperCase();

  //   setSelectedValue(uppercaseValue);
  //   setEditCity(uppercaseValue);
  //   window.city = uppercaseValue;
  //   setShowDropdown(false);
  //   if (selectedValue.length > 0) {
  //     setShowBrands(true);
  //   } else {
  //     setShowBrands(false);
  //   }
  // }


  const handleOptionClick = (value) => {
    const uppercaseValue = value.toUpperCase();

    setSelectedValue(uppercaseValue);
    setEditCity(uppercaseValue);
    window.city = value;
    setShowDropdown(false);
  };

  // Use useEffect to watch for changes in selectedValue
  useEffect(() => {
    if (selectedValue.length > 0) {
      setShowBrands(true);
    } else {
      setShowBrands(false);
    }
  }, [selectedValue]);

  const option = [
    "pune",
    "New Mumbai",
    "Mumbai",
    "thane",
    "kalyan",
    "pen Raigad",
    "sindhudurg",
    "ratnagiri",
    "kolhapur",
    "satara",
    "sangli",
    "solapur",
    "pimpri Chinchwad",
    "nashik",
    "ahmednagar",
    "shrirampur",
    "dhule",
    "jalgaon",
    "aurangabad",
    "jalna",
    "beed",
    "latur",
    "osmanabad",
    "nanded",
    "amravati",
    "buldhana",
    "yavatmal",
    "akola",
    "nagpur",
    "wardha",
    "gadchiroli",
    "chandrapur",
    "gondia",
    "bhandara",
    "washim",
    "hingoli",
    "nandurbar",
    "nagpur Gramin",
    "malegaon",
    "baramati",
    "vashi",
    "ambajogai",
    "akluj",
    "panvel",
    "borivali",
    "vasai Virar",
    "kharad",
    "parbhani",
    "thane Rural",
    "nashik Rural",
  ];
  //=========== for brand ==============
  const [options, setOptions] = useState([]);
  const [showBrands, setShowBrands] = useState();
  const [selectedBrand, setSelectedBrand] = useState("");
  useEffect(() => {
    let data = formData;
    let url = `${constant.baseurl}brand`;
    ApiGetMethod(url, data).then((response) => {
      setOptions(response.brand);
    });
  }, []);

  function brandFocus() {
    if (selectedValue.length > 0) {
      setShowBrands(true);
    } else {
      setShowBrands(false);
    }
    setShowBrands(true);
    setShowDropdown(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }
  function handleBrandSelect(value, id) {
    setSelectedBrand(value);
    setEditBrand(value);
    setShowBrands(false);
    const brandId = id;
    window.brandid = id;
    window.brandName = value;
    formData.append("id", brandId);
    let data = formData;
    let url = `${constant.baseurl}carModel`;
    ApiPostMethod(url, data).then((response) => {
      setCarModel(response.carModels);
    });
  }
  //========= for year =========
  const [carYear, setCarYear] = useState([]);
  const [showYears, setShowYears] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");

  function yearFocus() {
    let data = formData;
    let url = `${constant.baseurl}yearDropDown`;
    ApiGetMethod(url, data).then((response) => {
      setCarYear(response.carDataYear);
    });
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(true);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }

  function yearSelect(value) {
    setSelectedYear(value);
    setEditYear(value);
    window.year = value;
    setShowYears(false);
    setShowModels(true);
  }

  //========= for model ========
  const [carModel, setCarModel] = useState([]);
  const [showModels, setShowModels] = useState(false);
  const [selectedModel, setSelectedModel] = useState("");

  function modelFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(true);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }

  function modelSelect(value, id) {
    setSelectedModel(value);
    setEditModel(value);
    window.model = value;
    setShowModels(false);
    const varientId = id;
    const varientModel = value;
    formData.append("id", varientId);
    formData.append("model", varientModel);
    let data = formData;
    let url = `${constant.baseurl}carVarient`;
    ApiPostMethod(url, data).then((response) => {
      setCarVarient(response.carVarients);
    });
    setShowVarients(true);
  }
  //========== for varient ==========
  const [carVarient, setCarVarient] = useState([]);
  const [showVarients, setShowVarients] = useState(false);
  const [selectedVarient, setSelectedVarient] = useState("");

  function varientFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(true);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }

  function varientSelect(value) {
    setSelectedVarient(value);
    setEditVariant(value);
    window.varient = value;
    setShowVarients(false);
    setShowOwner(true);
  }

  //========== for owner ==========
  const [showOwner, setShowOwner] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState("");

  function ownerFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(true);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }

  function handleOwner(value) {
    setSelectedOwner(value);
    setEditOwner(value);
    const ownerValue = value;
    if (value == "I am a Car Dealer") {
      const ownerCount = "4";
      window.owner = ownerCount;
    } else {
      const ownerCount = Number(ownerValue.replace(/\D/g, ""));
      window.owner = ownerCount;
    }
    setShowOwner(false);
    setShowKM(true);
  }

  const owner = ["1st Owner", "2nd Owner", "3rd Owner","4th Owner","5th Owner", "I am a Car Dealer"];

  //========== for km ==========
  const [showKM, setShowKM] = useState(false);
  const [selectedKM, setSelectedKM] = useState("");

  function KMFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(true);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }

  function handleKM(value) {
    setSelectedKM(value);
    setEditKms(value);
    setShowKM(false);
    setShowSell(true);
    const distanceString = value;
    const [distance1String, distance2String] = distanceString.split(" - ");
    const distance1 = Number(distance1String.replace(/\D/g, ""));
    const distance2 = distance2String ? Number(distance2String.replace(/\D/g, "")) : "";
    const roundedDistance = Math.round((distance1 + distance2) / 1000) * 1000;
    window.kms = distance2 || "200000";
    window.kmsrange = value;
  }

  const kms = [
    "0km - 10,000km",
    "10,000km - 20,000km",
    "20,000km - 30,000km",
    "30,000km - 40,000km",
    "40,000km - 50,000km",
    "50,000km - 60,000km",
    "60,000km - 70,000km",
    "70,000km - 80,000km",
    "80,000km - 90,000km",
    "90,000km - 1,00,000km",
    "1,00,000km - 1,50,000km",
    "1,50,000km or More",
  ];

  //========== for when to sell ==========
  const [showSell, setShowSell] = useState(false);
  const [selectedSell, setSelectedSell] = useState("");

  function sellFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(true);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }

  function handleSell(value) {
    setSelectedSell(value);
    setEditWtsell(value);
    window.whento = value;
    setShowSell(false);
    setShowInsurance(true);
  }

  const sell = [
    "Immediately",
    "Within a month",
    "After a month",
    "Just checking price",
  ];

  //========== for insurance ==========
  const [showInsurance, setShowInsurance] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState("");

  function insuranceFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(true);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(false);
  }

  function handleInsurance(value) {
    setSelectedInsurance(value);
    setEditInsurance(value);
    setShowInsurance(false);
    setShowRc(true);
    if (value == "Yes") {
      window.insurance = 1;
    } else {
      window.insurance = 0;
    }
  }

  const insurance = ["Yes", "No"];

  //========== for Rc ==========
  const [showRc, setShowRc] = useState(false);
  const [selectedRc, setSelectedRc] = useState("");

  function rcFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(true);
    setShowTransmission(false);
    setShowColor(false);
  }

  function handleRc(value) {
    setSelectedRc(value);
    setEditRc(value);
    setShowRc(false);
    setShowTransmission(true);
    if (value == "Yes") {
      window.rc = 1;
    } else {
      window.rc = 0;
    }
  }

  const rc = ["Yes", "No"];
  //========== for Transmission ==========
  const [showTransmission, setShowTransmission] = useState(false);
  const [selectedTransmission, setSelectedTransmission] = useState("");

  function transmissionFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(true);
    setShowColor(false);
  }

  function handleTransmission(value) {
    setSelectedTransmission(value);
    setEditTransmission(value);
    window.transmission = value;
    setShowTransmission(false);
    setShowColor(true);
  }

  const transmission = ["automatic", "manual"];

  //========== for Transmission ==========
  const [showColor, setShowColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");

  function colorFocus() {
    setShowDropdown(false);
    setShowBrands(false);
    setShowYears(false);
    setShowModels(false);
    setShowVarients(false);
    setShowOwner(false);
    setShowKM(false);
    setShowSell(false);
    setShowInsurance(false);
    setShowRc(false);
    setShowTransmission(false);
    setShowColor(true);
  }

  function handleColor(value) {
    setSelectedColor(value);
    setEditColor(value);
    window.color = value;
    setShowColor(false);
  }
  //========== for depriciation ==========
  function handleCarPost() {
    const isImagesEmpty = selectedImages.length === 0;
    const isColorEmpty = typeof window.color !== "string" || window.color.trim() === "";
    if(!selectedValue || !selectedBrand || !carNumber || !selectedModel || !selectedVarient || !selectedYear || !selectedOwner || !selectedKM || !selectedSell || !selectedInsurance || !selectedRc || !selectedTransmission || isColorEmpty){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "All fields are mandatory kindly fill all the fields!",
      });
      return;
    }
    if(selectedValue == 'PUNE'){
      if (!selectedBrand || !carNumber || !selectedModel || !selectedVarient || !selectedYear || !selectedOwner || !selectedKM || !selectedSell || !selectedInsurance || !selectedRc || !selectedTransmission || isColorEmpty) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "All fields are mandatory kindly fill all the fields!",
        });
        return;
      }
    }else{
      if (isImagesEmpty) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select at least one image!",
        });
        return;
      }
    }

    const userid = localStorage.hasOwnProperty("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    const userId = localStorage.hasOwnProperty("user") ? userid._id : "";
    for (let i = 0; i < selectedImages.length; i++) {
      window.carImage = selectedImages[i];
    }
    const carPostFormData = {
      brandId: window.brandid,
      model: window.model,
      varient: window.varient,
      year: window.year,
      city: window.city,
      owner: window.owner,
      kms: window.kms,
      whenToSell: window.whento,
      insurence: window.insurance,
      rc: window.rc,
      transmission: window.transmission,
      color: window.color,
      carno: carNumber,
    };
    const formData = new FormData();
    for (let i = 0; i < selectedImages.length; i++) {
      formData.append(`image`, selectedImages[i]);
    }
    formData.append("userId", userId);
    formData.append("brandId", window.brandid);
    formData.append("brandName", window.brandName);
    formData.append("model", window.model);
    formData.append("varient", window.varient);
    formData.append("year", window.year);
    formData.append("city", window.city.toLowerCase());
    formData.append("owner", window.owner);
    formData.append("kms", window.kms);
    formData.append("whenToSell", window.whento);
    formData.append("insurence", window.insurance);
    formData.append("rc", window.rc);
    formData.append("transmission", window.transmission);
    formData.append("color", window.color);
    formData.append("carNumber", carNumber);
    let data = formData;
    let url = `${constant.baseurl}carData`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        localStorage.setItem("deprication", JSON.stringify(response));
        navigate("/Appoinment?SelectedCity=" + window.city);
      } else {
        localStorage.setItem("formData", JSON.stringify(carPostFormData));
        localStorage.setItem("sellCar", true);
        Swal.fire({
          title: "Error!",
          text: response.message,
          icon: "info",
          confirmButtonText: "OK",
        }).then((result) => {
          if (response.navigate) {
            navigate("/Login");
          } else {
            navigate("/carPost");
          }
        });
      }
    });
  }
  //========== for car number ==========
  const [carNumber, setCarNumber] = useState("");
  const handleCarNumber = (event) => {
    const inputValue = event.target.value.toUpperCase();
    setCarNumber(inputValue);
    setEditCnumber(inputValue);
    window.carPlateno = carNumber;
  };
  return (
    <div>
      <form action="" className="carpost_head">
        <div className="container carpost_container">
          <div className="col-12 d-flex flex-wrap position-relative carpost_wrapper">
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="" title="city">
                  City
                </label>
                <input
                  type="text"
                  ref={inputRef[0]}
                  placeholder="City"
                  className="input-fields"
                  onFocus={handleFocus}
                  value={editCity || selectedValue}
                  name="city"
                />
                {showDropdown && (
                  <div
                    className="focus-dropdown col-6 d-flex flex-wrap justify-content-between "
                    style={{ maxHeight: "300px" }}
                  >
                    {option.map((option) => (
                      <div
                        key={option}
                        onClick={() => handleOptionClick(option)}
                        className={`list-item col-3 m-1 d-flex align-items-center justify-content-center`}
                        style={{ width: "30%", textTransform: "capitalize" }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="">Brand</label>
                <input
                  type="text"
                  ref={inputRef[1]}
                  placeholder="Brand"
                  className="input-fields"
                  onFocus={brandFocus}
                  value={editBrand || selectedBrand}
                  name="brand"
                />
                {showBrands && (
                  <div className="focus-dropdown col-6 d-flex flex-wrap">
                    {options.map((option) => (
                      <div
                        key={option.brand._id}
                        className="col-4 brand_items11"
                        onClick={() =>
                          handleBrandSelect(
                            option.brand.brandName,
                            option.brand._id
                          )
                        }
                        title={option.brand._id}
                      >
                        <div className="col-12 text-center  brand-item">
                          <div className="brand_image">
                            <img src={option.brand.brandImage} alt="" />
                          </div>
                          <h6 className="brand_text">{option.brand.brandName}</h6>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="">Car Number</label>
                <input
                  type="text"
                  ref={inputRef[2]}
                  placeholder="Car number"
                  className="input-fields"
                  name="carnumber"
                  onChange={handleCarNumber}
                  value={editCnumber || carNumber}
                />
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="">Year</label>
                <input
                  type="text"
                  ref={inputRef[3]}
                  placeholder="Year"
                  className="input-fields"
                  name="year"
                  onFocus={yearFocus}
                  value={editYear || selectedYear}
                />
                {showYears && (
                  <div className="focus-dropdown col-5 d-flex flex-wrap">
                    {carYear.map((option) => (
                      <div
                        key={option}
                        className="col-4"
                        onClick={() => yearSelect(option)}
                      >
                        <div className="col-12 text-center">
                          <h6 className="m-0">{option}</h6>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="">Model</label>
                <input
                  type="text"
                  ref={inputRef[4]}
                  placeholder="Model"
                  className="input-fields"
                  name="model"
                  onFocus={modelFocus}
                  value={editModel || selectedModel}
                />
                {showModels && (
                  <div className="focus-dropdown col-3">
                    {carModel.map((option) => (
                      <div
                        key={option._id}
                        className="list-item"
                        onClick={() => modelSelect(option.model, option.brand)}
                        title={option._id}
                      >
                        <div className="col-12 text-center">
                          <h6 className="m-0">{option.model}</h6>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="">Variant</label>
                <input
                  type="text"
                  ref={inputRef[5]}
                  placeholder="Variant"
                  className="input-fields"
                  name="varient"
                  onFocus={varientFocus}
                  value={editVariant || selectedVarient}
                />
                {showVarients && (
                  <div className="focus-dropdown col-3">
                    {carVarient.map((option) => (
                      <div
                        key={option.varient}
                        className="list-item"
                        onClick={() => varientSelect(option.varient)}
                        title={option.varient}
                      >
                        <div className="col-12 text-center">
                          <h6 className="m-0">{option.varient}</h6>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="" title="city">
                  Owner
                </label>
                <input
                  type="text"
                  ref={inputRef[6]}
                  placeholder="Owner"
                  className="input-fields"
                  onFocus={ownerFocus}
                  value={editOwner || selectedOwner}
                  name="owner"
                />
                {showOwner && (
                  <div className="focus-dropdown">
                    {owner.map((option) => (
                      <div
                        key={option}
                        onClick={() => handleOwner(option)}
                        className="list-item"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="" title="city">
                  KMs
                </label>
                <input
                  type="text"
                  ref={inputRef[7]}
                  placeholder="KMs driven"
                  className="input-fields"
                  onFocus={KMFocus}
                  value={editKms || selectedKM}
                  name="km"
                />
                {showKM && (
                  <div className="focus-dropdown">
                    {kms.map((option) => (
                      <div
                        key={option}
                        onClick={() => handleKM(option)}
                        className="list-item"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="" title="city">
                  When to sell
                </label>
                <input
                  type="text"
                  ref={inputRef[8]}
                  placeholder="When to sell"
                  className="input-fields"
                  onFocus={sellFocus}
                  value={editWtsell || selectedSell}
                  name="whentosell"
                />
                {showSell && (
                  <div className="focus-dropdown">
                    {sell.map((option) => (
                      <div
                        key={option}
                        onClick={() => handleSell(option)}
                        className="list-item"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="" title="city">
                  Insurance
                </label>
                <input
                  type="text"
                  ref={inputRef[9]}
                  placeholder="Insurance"
                  className="input-fields"
                  onFocus={insuranceFocus}
                  value={editInsurance || selectedInsurance}
                  name="insurance"
                />
                {showInsurance && (
                  <div className="focus-dropdown col-2">
                    {insurance.map((option) => (
                      <div
                        key={option}
                        onClick={() => handleInsurance(option)}
                        className="list-item"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="" title="city">
                  Rc
                </label>
                <input
                  type="text"
                  ref={inputRef[10]}
                  placeholder="Rc"
                  className="input-fields"
                  onFocus={rcFocus}
                  value={editRc || selectedRc}
                  name="rc"
                />
                {showRc && (
                  <div className="focus-dropdown col-2">
                    {rc.map((option) => (
                      <div
                        key={option}
                        onClick={() => handleRc(option)}
                        className="list-item"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="" title="city">
                  Transmission
                </label>
                <input
                  type="text"
                  ref={inputRef[11]}
                  placeholder="Transmission"
                  className="input-fields"
                  onFocus={transmissionFocus}
                  value={editTransmission || selectedTransmission}
                  name="transmission"
                />
                {showTransmission && (
                  <div className="focus-dropdown col-2">
                    {transmission.map((option) => (
                      <div
                        key={option}
                        onClick={() => handleTransmission(option)}
                        className="list-item"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field">
                <label htmlFor="" title="city">
                  Color
                </label>
                <input
                  type="text"
                  ref={inputRef[11]}
                  placeholder="Color"
                  className="input-fields"
                  onFocus={colorFocus}
                  value={editColor || selectedColor}
                  name="color"
                />
                {showColor && (
                  <div className="focus-dropdown col-4 d-flex flex-wrap">
                    {colorOptions.map((option) => (
                      <div
                        key={option.name}
                        onClick={() => handleColor(option.name)}
                        className="list-item col-4"
                        style={{ width: "30.3333%", margin: "10px 5px" }}
                      >
                        {option.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-2 input_block">
              <div className="input_field input-last">
                <input
                  type="file"
                  multiple
                  onChange={handleImageSelection}
                  placeholder="Add image"
                  id="input-fields"
                  hidden
                />
                <label htmlFor="input-fields" className="input-fields-image">
                  Add image
                </label>
              </div>
            </div>
            <div className="preview_block">
              {/* {editImage.map((url, index) => (
                              <div key={index} style={{ marginRight: '10px' }}>
                              <img src={url} alt="preview" style={{ width: '150px', height: '100px' }} />
                              </div>
                            )) || */}
              {previewImageUrls.map((url, index) => (
                <div key={index} style={{ marginRight: "10px" }}>
                  <img
                    src={url}
                    alt="preview"
                    style={{ width: "150px", height: "100px" }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center car_post_btn">
          <button
            type="button"
            className="car-post-submit"
            onClick={() => handleCarPost()}
          >
            Get Price
          </button>
        </div>
      </form>
    </div>
  );
};
export default MyForm;
