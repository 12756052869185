import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container ,Image} from "react-bootstrap";
import CarFilter from "../filters/carFilters";
import BottomNavbar from "../navbar/bottomNavBar";
import { useNavigate } from "react-router-dom";
import WishlistButton from "../sharemodal/whishlistButton";
import Spinner from "react-bootstrap/Spinner";
import "./search.css";
import constant from "../js/constant";
import { ApiGetMethod, ApiPostMethod } from "../js/helper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import badge from "../index/inspectbadge.png"


const options = [
  { id: 1, value: "Default" },
  { id: 2, value: "Year - old to New" },
  { id: 3, value: "Year - New to old" },
];

const CarSearch = () => {
  const [filterCars, setFilterCars] = useState([]);
  const [filterOption, setFilterOption] = useState([]);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showMobilecarMenu, setShowMobilecarMenu] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 991) {
      setShowMobilecarMenu(false);
    } else {
      if (window.innerWidth < 991) {
        setShowMobilecarMenu(true);
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        setShowMobilecarMenu(false);
      } else if (window.innerWidth < 991) {
        setShowMobilecarMenu(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleApiResponse = (data) => {
    setFilterCars(data)
  };
  
  const handleFilterOption = (data) => {
    setFilterOption(data)
  }

  const userInfo = localStorage.hasOwnProperty("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";

  const [reloadFlag, setReloadFlag] = useState(false);

  useEffect(() => {
    setReloadFlag(true);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

useEffect(() => {
  if(filterCars.length > 0){
    setIsLoading(false)
  }else{
    setIsLoading(true)
  }
},[filterCars, handleApiResponse])

const handleloading = (data) =>{
  if(data === true){
    setIsLoading(true)
  }else{
    setIsLoading(false)
  }
}

const handleOptionChange = (option) => {
  setSelectedOption(option);
  toggleDropdown();
};

useEffect(() => {
  if (selectedOption.id === 1) {
    const sortedData = filterCars
    setFilterCars(sortedData);
  } else if (selectedOption.id === 2) {
    const sortedData = [...filterCars].sort((a, b) => a.carPost.year - b.carPost.year);
    setFilterCars(sortedData);
  } else if (selectedOption.id === 3) {
    const sortedData = [...filterCars].sort((a, b) => b.carPost.year - a.carPost.year);
    setFilterCars(sortedData);
  }
  
}, [filterCars, selectedOption]);

const [adsContent, setAdsContent] = useState([]);
const formData = new FormData();

useEffect(()=>{
  let data = formData;
  let url = `${constant.baseurl}viewAllcontent`;
  ApiGetMethod(url)
  .then((response)=>{
    if(response.error === false){
      setAdsContent(response.Content)
    }else{
    }
  })
},[])

const adscont = {
  loop: true,
  autoplay: true,
  autoplaySpeed: 2500,
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  className: "center",
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
  lazyLoad: false,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const [adsSliderContent, setAdsSliderContent] = useState([]);

  useEffect(() => {
    updateAdsSliderContent();
  }, [filterCars, handleApiResponse]);
  // const updateAdsSliderContent = () => {
  //   const newAdsSliderContent = [];

  //   for (let i = 0; i < filterCars.length; i++) {
  //     newAdsSliderContent.push(filterCars[i]);
  //     if ((i + 1) % 6 === 0 && adsContent.length > 0) {
  //       newAdsSliderContent.push({
  //         type: "adsContent",
  //         data: adsContent
  //       });
  //     }
  //   }

  //   setAdsSliderContent(newAdsSliderContent);
  // };

  const updateAdsSliderContent = () => {
    const newAdsSliderContent = [];
    const adsContentLength = adsContent.length;
    const filterCarsLength = filterCars.length;
  
    let carIndex = 0;
    let adsIndex = 0;
  
    while (carIndex < filterCarsLength || adsIndex < adsContentLength) {
      for (let i = 0; i < 6; i++) {
        if (carIndex < filterCarsLength) {
          newAdsSliderContent.push(filterCars[carIndex]);
          carIndex++;
        }
      }
      if (adsIndex < adsContentLength) {
        if(filterCarsLength < 12) {
          const adsData = adsContent.slice(adsIndex, adsIndex + 6);
          newAdsSliderContent.push({
            type: "adsContent",
            data: adsData,
          });
          adsIndex += 6;
        }else{
          const adsData = adsContent.slice(adsIndex, adsIndex + 3);
          newAdsSliderContent.push({
            type: "adsContent",
            data: adsData,
          });
          adsIndex += 3;
        }
      }
    }
  
    setAdsSliderContent(newAdsSliderContent);
  };

  // const updateAdsSliderContent = () => {
  //   const newAdsSliderContent = [];
  //   const adsContentLength = adsContent.length;
  //   const filterCarsLength = filterCars.length;
  
  //   let carIndex = 0;
  //   let adsIndex = 0;
  
  //   while (carIndex < filterCarsLength || adsIndex < adsContentLength) {
  //     for (let i = 0; i < 6; i++) {
  //       if (carIndex < filterCarsLength) {
  //         newAdsSliderContent.push(filterCars[carIndex]);
  //         carIndex++;
  //       }
  //     }
  
  //     if (adsIndex < adsContentLength) {
  //       const remainingCars = 6 - (newAdsSliderContent.length % 6);
  //       if (remainingCars >= 3) {
  //         const adsData = adsContent.slice(adsIndex, adsIndex + 3);
  //         newAdsSliderContent.push({
  //           type: "adsContent",
  //           data: adsData,
  //         });
  //         adsIndex += 3;
  //       } else if (remainingCars > 0) {
  //         const adsData = adsContent.slice(adsIndex, adsIndex + remainingCars);
  //         newAdsSliderContent.push({
  //           type: "adsContent",
  //           data: adsData,
  //         });
  //         adsIndex += remainingCars;
  //       }
  //     }
  //   }
  
  //   setAdsSliderContent(newAdsSliderContent);
  // };

  // const updateAdsSliderContent = () => {
  //   const newAdsSliderContent = [];
  //   const adsContentLength = adsContent.length;
  //   const filterCarsLength = filterCars.length;
  
  //   let carIndex = 0;
  //   let adsIndex = 0;
  
  //   while (newAdsSliderContent.length < 6) {
  //     if (carIndex < filterCarsLength) {
  //       newAdsSliderContent.push(filterCars[carIndex]);
  //       carIndex++;
  //     } else {
  //       carIndex = 0;
  //     }
  
  //     if (adsIndex < adsContentLength) {
  //       const adsData = adsContent.slice(adsIndex, adsIndex + 3);
  //       newAdsSliderContent.push({
  //         type: "adsContent",
  //         data: adsData,
  //       });
  //       adsIndex += 3;
  //     }
  //   }
  
  //   setAdsSliderContent(newAdsSliderContent);
  // };
  
  const homeCity = localStorage.getItem("homeCity")
  
  return (
    <>
      <Container fluid className="search-page d-flex top_class">
        {showMobilecarMenu ? (
          <>
            <Button
              className="buttn-filter"
              onClick={handleShow}
              style={{ position: "absolute", right: "20%" }}
            >
              Filter
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Filter</Modal.Title>
              </Modal.Header>

              <CarFilter onApiResponse={handleApiResponse} onFilterSelect={handleFilterOption} reloadFlag={reloadFlag} loading={handleloading}/>
            </Modal>
          </>
        ) : (
          <Col className="search-setion d-flex">
            <Col lg="12" className="filter-section">
              <CarFilter onApiResponse={handleApiResponse} onFilterSelect={handleFilterOption} reloadFlag={reloadFlag} loading={handleloading}/>
            </Col>
          </Col>
        )}
        <Col lg="9" className="filteredCrs-section">
        <Col lg="12">
            <Col lg="12" className="d-flex justify-content-between car_numbers">
              <h5 className="d-flex align-items-center ps-3">
                <h3 className="font-weight-bold m-0">{filterCars.length}</h3> used cars in {homeCity}
              </h5>
              <div className={`Sort-dropdown ${isOpen ? "open" : ""}`}>
                <Button className="buttn-sort" onClick={toggleDropdown}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask id="path-1-inside-1_92_3133" fill="white">
                      <path d="M16.875 20C17.3927 20 17.8125 20.4198 17.8125 20.9375C17.8125 21.4552 17.3927 21.875 16.875 21.875H13.125C12.6073 21.875 12.1875 21.4552 12.1875 20.9375C12.1875 20.4198 12.6073 20 13.125 20H16.875ZM20.625 13.75C21.1427 13.75 21.5625 14.1698 21.5625 14.6875C21.5625 15.2052 21.1427 15.625 20.625 15.625H9.375C8.85724 15.625 8.4375 15.2052 8.4375 14.6875C8.4375 14.1698 8.85724 13.75 9.375 13.75H20.625ZM24.375 7.5C24.8927 7.5 25.3125 7.91974 25.3125 8.4375C25.3125 8.95526 24.8927 9.375 24.375 9.375H5.625C5.10724 9.375 4.6875 8.95526 4.6875 8.4375C4.6875 7.91974 5.10724 7.5 5.625 7.5H24.375Z" />
                    </mask>
                    <path
                      d="M16.875 20C17.3927 20 17.8125 20.4198 17.8125 20.9375C17.8125 21.4552 17.3927 21.875 16.875 21.875H13.125C12.6073 21.875 12.1875 21.4552 12.1875 20.9375C12.1875 20.4198 12.6073 20 13.125 20H16.875ZM20.625 13.75C21.1427 13.75 21.5625 14.1698 21.5625 14.6875C21.5625 15.2052 21.1427 15.625 20.625 15.625H9.375C8.85724 15.625 8.4375 15.2052 8.4375 14.6875C8.4375 14.1698 8.85724 13.75 9.375 13.75H20.625ZM24.375 7.5C24.8927 7.5 25.3125 7.91974 25.3125 8.4375C25.3125 8.95526 24.8927 9.375 24.375 9.375H5.625C5.10724 9.375 4.6875 8.95526 4.6875 8.4375C4.6875 7.91974 5.10724 7.5 5.625 7.5H24.375Z"
                      fill="#FFFEFE"
                    />
                    <path
                      d="M16.875 22C16.2882 22 15.8125 21.5243 15.8125 20.9375H19.8125C19.8125 19.3152 18.4973 18 16.875 18V22ZM15.8125 20.9375C15.8125 20.3507 16.2882 19.875 16.875 19.875V23.875C18.4973 23.875 19.8125 22.5598 19.8125 20.9375H15.8125ZM16.875 19.875H13.125V23.875H16.875V19.875ZM13.125 19.875C13.7118 19.875 14.1875 20.3507 14.1875 20.9375H10.1875C10.1875 22.5598 11.5027 23.875 13.125 23.875V19.875ZM14.1875 20.9375C14.1875 21.5243 13.7118 22 13.125 22V18C11.5027 18 10.1875 19.3152 10.1875 20.9375H14.1875ZM13.125 22H16.875V18H13.125V22ZM20.625 15.75C20.0382 15.75 19.5625 15.2743 19.5625 14.6875H23.5625C23.5625 13.0652 22.2473 11.75 20.625 11.75V15.75ZM19.5625 14.6875C19.5625 14.1007 20.0382 13.625 20.625 13.625V17.625C22.2473 17.625 23.5625 16.3098 23.5625 14.6875H19.5625ZM20.625 13.625H9.375V17.625H20.625V13.625ZM9.375 13.625C9.96182 13.625 10.4375 14.1007 10.4375 14.6875H6.4375C6.4375 16.3098 7.75265 17.625 9.375 17.625V13.625ZM10.4375 14.6875C10.4375 15.2743 9.96182 15.75 9.375 15.75V11.75C7.75265 11.75 6.4375 13.0652 6.4375 14.6875H10.4375ZM9.375 15.75H20.625V11.75H9.375V15.75ZM24.375 9.5C23.7882 9.5 23.3125 9.02432 23.3125 8.4375H27.3125C27.3125 6.81515 25.9973 5.5 24.375 5.5V9.5ZM23.3125 8.4375C23.3125 7.85068 23.7882 7.375 24.375 7.375V11.375C25.9973 11.375 27.3125 10.0598 27.3125 8.4375H23.3125ZM24.375 7.375H5.625V11.375H24.375V7.375ZM5.625 7.375C6.21181 7.375 6.6875 7.85069 6.6875 8.4375H2.6875C2.6875 10.0598 4.00267 11.375 5.625 11.375V7.375ZM6.6875 8.4375C6.6875 9.02431 6.21181 9.5 5.625 9.5V5.5C4.00267 5.5 2.6875 6.81517 2.6875 8.4375H6.6875ZM5.625 9.5H24.375V5.5H5.625V9.5Z"
                      fill="#FFFEFE"
                      mask="url(#path-1-inside-1_92_3133)"
                    />
                  </svg>
                  <span className="sort_options">
                    {selectedOption.value ? selectedOption.value : "Sort"}
                  </span>
                </Button>

                <ul className={isOpen ? "Sort-menu menu-expand" : "Sort-menu"}>
                  {options.map((option) => (
                    <li key={option.id}>
                      <input
                        type="radio"
                        id={option.id}
                        name="option"
                        value={option.value}
                        checked={selectedOption.id === option.id}
                        onChange={() => handleOptionChange(option)}
                        hidden
                      />
                      <label htmlFor={option.id}>{option.value}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Col>
          <div className="col-12 d-flex flex-wrap luxary_car_wrapper">
            {adsSliderContent.map((item, index) => (
                item.type === "adsContent" ? (
                  <div className={filterCars.length > 0 ? "ad-content col-12" : "ad-content col-12 d-none"}>
                    <Slider {...adscont}>
                      {item.data.map((ads, adIndex) => (
                        <div key={adIndex}>
                          <div className="carousel-slide">
                            <a href={ads.url} target="_blank"><img src={ads.compImage} style={{height:"200px",objectFit:"initial"}} className="m-0 w-100"/></a>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                ) : (
                // If it's a filter car, render the filter car content
                  <div key={index} className="luxuary-sell-slide col-4">
                    <div className="col-12 car-details luxuary-car ">
                    {
                            item.carPost.city === 'pune'?
                            <div className="inspected_badge" >
                             <div>
                              {/* <FaAward/> */}
                              <Image class="badge_img" src={badge} style={{height:"20px"}} />
                              </div> </div>: ""
                            }
                      <Col className="luxy_img">
                      <img src={item.carPost.carImages[0]} alt="" onClick={() => navigate("/SingleCarDetail?carPostId=" + item.carPost._id)} />
                      </Col>
                     <Col className="luxy_wrap" >
                      <Col lg="12" className="details">
                        <Col lg="12" className="d-flex justify-content-between">
                          <Col lg="10" onClick={() => navigate("/SingleCarDetail?carPostId=" + item.carPost._id )}>
                            <h5 className="mb-1">
                              {item.carPost.brandId}{" "}
                              {item.carPost.year}
                            </h5>
                            {/* <h5 className="mb-2">{item.carPost.model}{" "} {item.carPost.varient} -{" "} {item.carPost.carNumber ? item.carPost.carNumber.replace(/\s/g, "").substring(0, 4) : ""} </h5> */}
                            <h6 className="mb-2">
  {`${item.carPost.model} ${item.carPost.varient} - ${
    item.carPost.carNumber ? item.carPost.carNumber.replace(/\s/g, "").substring(0, 4)+ "-XX-XXXX" : ""
  }`.substring(0, 20)}
  {`${item.carPost.model} ${item.carPost.varient} - ${
    item.carPost.carNumber ? item.carPost.carNumber.replace(/\s/g, "").substring(0, 4)+ "-XX-XXXX" : ""
  }`.length > 20 ? "..." : ""}
</h6>
                          
                          </Col>
                          <Col lg="1" className={localStorage.hasOwnProperty("user") ? "" : "d-none"}>
                            <WishlistButton
                              wishlist={item.wishlisted}
                              uPostId={item.carPost._id}
                              userId={userInfo._id}
                            />
                          </Col>
                        </Col>
                        <Col lg="12">
                          <h6>{(item.carPost.kms / 1000).toLocaleString("en-US", {maximumFractionDigits: 1,}) + "k"}{" "}km{" "}
                            <span>
                              <svg
                                width="5"
                                height="5"
                                viewBox="0 0 5 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="2.5"
                                  cy="2.5"
                                  r="2.5"
                                  fill="black"
                                />
                              </svg>
                            </span>{" "}
                            <span className="text-capitalize">
                              {item.fuelType}
                            </span>{" "}
                            <span>
                              <svg
                                width="5"
                                height="5"
                                viewBox="0 0 5 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="2.5"
                                  cy="2.5"
                                  r="2.5"
                                  fill="black"
                                />
                              </svg>
                            </span>{" "}
                            {item.carPost.transmission}
                          </h6>
                        </Col>
                      </Col>
                      <Col lg="12" className="p-2">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_434_2834)">
                             <path
                              d="M18.3814 8.46854C18.3814 14.3784 10.783 19.444 10.783 19.444C10.783 19.444 3.18457 14.3784 3.18457 8.46854C3.18457 6.45331 3.98512 4.52062 5.4101 3.09564C6.83508 1.67066 8.76777 0.870117 10.783 0.870117C12.7982 0.870117 14.7309 1.67066 16.1559 3.09564C17.5809 4.52062 18.3814 6.45331 18.3814 8.46854Z"
                              stroke="#333333"
                              stroke-width="1.71704"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.7828 11.0014C12.1816 11.0014 13.3156 9.86743 13.3156 8.4686C13.3156 7.06977 12.1816 5.93579 10.7828 5.93579C9.38398 5.93579 8.25 7.06977 8.25 8.4686C8.25 9.86743 9.38398 11.0014 10.7828 11.0014Z"
                              stroke="#333333"
                              stroke-width="1.71704"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_434_2834">
                              <rect
                                width="20.2625"
                                height="20.2625"
                                fill="white"
                                transform="translate(0.651611 0.026001)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="m-1 car11_span">Cars 2 Cars Hub, {item.carPost.city}</span>
                      </Col>
                      </Col>
                    </div>
                  </div>
                )
            ))}
          </div>
        </Col>
      </Container>

    </>
  );
};
export default CarSearch;