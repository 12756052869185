import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import constant from "../js/constant";
import { ApiPostMethod } from "../js/helper";
import "./userProfile.css";
import Spinner from "react-bootstrap/Spinner";
import notfoundImage from "../../image/not-foundimg.png";
import { NavLink, useNavigate } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const UserBooking = () => {
  const navigate = useNavigate();
  const userIdFCPost = localStorage.hasOwnProperty("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const [errorMessage, setErrorMessage] = useState(false);
  const [bookedCars, setBookedCars] = useState([]);
  //   const [userPostedCar, setUserPostedCar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formData = new FormData();
  useEffect(() => {
    setIsLoading(true);
    formData.append("userId", userIdFCPost._id);
    let data = formData;
    let url = `${constant.baseurl}viewBookingofUser`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        setErrorMessage(false);
        setBookedCars(response.booked);
        setIsLoading(false);
      } else {
        setErrorMessage(true);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <>
      <Breadcrumb className="bread_crumb">
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Booking</Breadcrumb.Item>
      </Breadcrumb>

      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <Container fluid>
          {errorMessage ? (
            <Col
              lg="12"
              className="not-found d-flex justify-content-center align-items-center"
            >
              <Col lg="8" className="text-center">
                <div className="notfound-img d-flex justify-content-center">
                  <img src={notfoundImage} alt="car Not Found" />
                </div>
                <h5 className="text-color font-weight-bold">
                  No Car's Found !!!
                </h5>
                <h6 className="text-grey">Please make an offer.</h6>
                <NavLink to="/" className="btn-cars_cars">
                  Back
                </NavLink>
              </Col>
            </Col>
          ) : (
            <ul className="CarpostList p-0">
              {bookedCars.map((car) => (
                <li className="post-list-item" key={car._id}>
                  <Col
                    lg="12"
                    className="userPost d-flex justify-content-between"
                  >
                    <Col lg="3" className="postedcarImage">
                      <img
                        src={`http://localhost:5000/dist/img/carPost/${car.carImages[0]}`}
                        alt=""
                        className="w-100"
                      />
                    </Col>
                    <Col lg="5" className="postedCarDetails">
                      <h5>
                        <span>{car.brand}</span>
                        <span>{car.model}</span> <span>{car.year}</span>
                        <span className="text-uppercase">{car.carNumber
                          ? car.carNumber
                              .replace(/\s/g, "")
                              .substring(0, 4) + "-XX-XXXX"
                          : ""}
                        </span>
                      </h5>
                      <h6>
                        <span>{car.kms}kms </span>
                        <span>{car.varient}</span>{" "}
                        <span>{car.transmission}</span>
                      </h6>
                      <h6>
                        <span>
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1204_2121)">
                              <path
                                d="M16.625 8.50793C16.625 14.0496 9.5 18.7996 9.5 18.7996C9.5 18.7996 2.375 14.0496 2.375 8.50793C2.375 6.61827 3.12567 4.806 4.46186 3.4698C5.79806 2.1336 7.61033 1.38293 9.5 1.38293C11.3897 1.38293 13.2019 2.1336 14.5381 3.4698C15.8743 4.806 16.625 6.61827 16.625 8.50793Z"
                                stroke="#333333"
                                stroke-width="1.61006"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.5 10.8829C10.8117 10.8829 11.875 9.81961 11.875 8.50793C11.875 7.19626 10.8117 6.13293 9.5 6.13293C8.18832 6.13293 7.125 7.19626 7.125 8.50793C7.125 9.81961 8.18832 10.8829 9.5 10.8829Z"
                                stroke="#333333"
                                stroke-width="1.61006"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1204_2121">
                                <rect
                                  width="19"
                                  height="19"
                                  fill="white"
                                  transform="translate(0 0.591309)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>{" "}
                        Cars 2 Cars Hub {car.city}
                      </h6>
                    </Col>
                    <Col
                      lg="3"
                      className="carpostbtn d-flex justify-content-center"
                    >
                      <Col lg="8">
                        <Button
                          className="btn-cars_cars "
                          onClick={() =>
                            navigate("/SingleCarDetail?carPostId=" + car._id)
                          }
                        >
                          View Details
                        </Button>
                      </Col>
                    </Col>
                  </Col>
                </li>
              ))}
            </ul>
          )}
        </Container>
      )}
    </>
  );
};
export default UserBooking;
